#tents {
  .container {
    row-gap: 2rem;
    width: 100%;
    padding: 1.5rem;
    max-width: 1920px;
    margin: auto;

    .head {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .best-choice {
        color: orange;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .our-eq {
        color: #1f3e72;
        font-weight: bold;
        font-size: 2rem;
      }
    }

    .cards-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      padding-top: 30px;
      .card {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        padding: 1rem;
        border-radius: 15px;
        border: 1.5px solid black;
        max-width: max-content;
        transition: all 300ms ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;

        &:hover {
          scale: 1.025;
          cursor: default;
          box-shadow: 5px 5px 23px 1px rgba(66, 68, 90, 1);
        }

        img {
          width: 15rem;
          height: 15rem;
          object-fit: contain;
          border-radius: 20px;
        }

        span {
          font-weight: 900;
          font-size: 1.2rem;
        }

        .name {
          color: #1f3e72;
        }

        .detail {
          color: rgb(140 139 139);
          font-size: 0.9rem;
          width: 15rem;
          height: 2rem;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .container {
      .cards-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
