#offer {
  .container {
    display: flex;
    row-gap: 2rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1.5rem;
    max-width: 1920px;
    margin: auto;

    div {
      flex: 1;
    }

    .left-side {
      .image-container {
        width: 38rem;
        height: 35rem;
        overflow: hidden;
        border-radius: 11rem 11rem 0 0;
        border: 8px solid rgba(0, 0, 0, 0.72);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 0.5rem;
      margin-left: 2rem;

      .title {
        color: orange;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .question {
        color: #1f3e72;
        font-weight: bold;
        font-size: 2rem;
      }

      .description {
        color: rgb(140 139 139);
        font-size: 0.9rem;
      }

      .accordion {
        margin-top: 2rem;
        border: none;
        width: 100%;

        .accordionItem {
          background: white;
          border: 0.8px solid rgba(128, 128, 128, 0.143);
          border-radius: 8px;
          overflow: hidden;
          margin-bottom: 20px;

          &.expanded {
            box-shadow: var(--shadow);
            border-radius: 5px;
          }

          .accordionButton {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: white;
            padding: 1rem;
            width: 100%;
            cursor: pointer;
            font-size: 1.1rem;

            .icon {
              display: flex;
              row-gap: 2rem;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              padding: 10px;
              background: #eeeeff;
              border-radius: 5px;

              svg {
                width: 18px;
                height: 18px;
                color: #1f3e72;
              }
            }

            .heading {
              width: 90%;
              display: flex;
              justify-content: center;
              text-align: center;
              color: #1f3e72;
              font-weight: bold;
              font-size: 0.9rem;
            }
          }

          .detail {
            color: rgb(140 139 139);
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .container {
      width: 100%;
      flex-direction: column;
      flex-wrap: unset;
      margin: auto;

      .right-side {
        margin: 0;
      }

      .left-side {
        .image-container {
          width: 95%;
          height: 25rem;
          margin: auto;
        }
      }
    }
  }
}
