#header {
  color: white;
  position: relative;
  padding-bottom: 2rem;
  background-color: var(--black);

  .container {
    display: flex;
    row-gap: 2rem;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1.5rem;
    max-width: 1920px;
    margin: auto;

    .container-left {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 3rem;

      .title {
        position: relative;
        z-index: 1;

        .orange-circle {
          width: 4rem;
          height: 4rem;
          background: var(--orange-gradient);
          border-radius: 999px;
          position: absolute;
          right: -2%;
          top: -8%;
          z-index: -1;
        }

        h1 {
          font-weight: 900;
          font-size: 3.8rem;
          line-height: 4rem;
        }
      }

      .description {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        span {
          color: rgb(140 139 139);
          font-size: 1rem;
          margin-top: 0.9rem;
        }
      }

      .stats {
        width: 100%;
        display: flex;
        row-gap: 2rem;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .stat {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          span {
            &:nth-child(1) {
              font-size: 2rem;

              .plus {
                font-size: 2rem;
                color: orange;
              }
            }
          }
        }
      }
    }

    .container-right {
      .image-container {
        width: 38rem;
        height: 35rem;
        overflow: hidden;
        border-radius: 11rem 11rem 0 0;
        border: 8px solid rgba(255, 255, 255, 0.12);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .container {
      .container-left {
        .title {
          .orange-circle {
          }

          h1 {
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }

        .description {
          span {
          }
        }

        .stats {
          justify-content: space-between;
          gap: 1.5rem;
          .stat {
            span {
              &:nth-child(1) {
                font-size: 1.5rem;
                .plus {
                  font-size: 1.5rem;
                }
              }
              font-size: 0.8rem;
            }
          }
        }
      }

      .container-right {
        width: 100%;
        .image-container {
          width: 95%;
          height: 25rem;
          margin: auto;
          img {
          }
        }
      }
    }
  }

  @media (max-width: 412px) {
    .container {
      .container-left {
        .title {
          .orange-circle {
            right: 45%;
          }
        }
      }
    }
  }
}
