#footer {
  border: 1px solid rgb(140 139 139);
  .container {
    display: flex;
    row-gap: 2rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1.5rem;
    max-width: 1920px;
    margin: auto;

    .left-side {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;

      .description {
        color: rgb(140 139 139);
        font-size: 0.9rem;
      }
    }

    .right-side {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .information {
        color: black;
        font-weight: bold;
        font-size: 0.9rem;
      }

      .address,
      .contact,
      .email {
        color: rgb(140 139 139);
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 1024px) {
  #footer {
    border: 0.5px solid rgb(140 139 139);
  }
}
