#contact {
  .container {
    display: flex;
    row-gap: 2rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1.5rem;
    max-width: 1920px;
    margin: auto;

    div {
      flex: 1;
    }

    .left-side {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      margin-right: 100px;

      .description-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .title {
          color: orange;
          font-size: 1.5rem;
          font-weight: 600;
        }

        .title2 {
          color: #1f3e72;
          font-weight: bold;
          font-size: 2rem;
        }

        .description {
          color: rgb(140 139 139);
          font-size: 0.9rem;
        }
      }

      .tables {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 2rem;
        gap: 1rem;

        .tables-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          gap: 1.5rem;

          .table {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 30rem;
            padding: 1rem;
            border: 0.8px solid rgba(128, 128, 128, 0.143);
            border-radius: 5px;
            gap: 1rem;
            transition: all 300ms ease-in-out;

            &:hover {
              scale: 1.1;
              box-shadow: var(--shadow);
            }

            .table-container {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex: unset;
              width: 100%;
              margin-left: 3rem;

              .icon {
                display: flex;
                row-gap: 2rem;
                align-items: center;
                flex-wrap: wrap;
                padding: 10px;
                background: #eeeeff;
                border-radius: 5px;
                height: 50px;
                flex: unset;
                margin-right: 4rem;
              }

              .detail {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                margin-right: 3rem;

                span:nth-child(1) {
                  color: #1f3e72;
                  font-size: 1.1rem;
                  font-weight: 600;
                }

                span:nth-child(2) {
                  color: rgb(140 139 139);
                  font-size: 0.9rem;
                }
              }
            }

            .button-container {
              width: 100%;

              .button {
                display: flex;
                row-gap: 2rem;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                background-color: var(--lightBlue);
                color: var(--blue);
                padding: 1rem;
                cursor: pointer;

                &:hover {
                  background: var(--blue-gradient);
                  color: white;
                }

                span {
                  font-size: 1rem;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }

    .right-side {
      width: 100%;

      .image-container {
        width: 38rem;
        height: 35rem;
        overflow: hidden;
        border-radius: 11rem 11rem 0 0;
        border: 8px solid rgba(0, 0, 0, 0.72);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .container {
      flex-direction: column;
      align-items: unset;

      .right-side {
        justify-content: center;

        .image-container {
          width: 85%;
          height: 25rem;
          margin: auto;
        }
      }

      .left-side {
        .tables {
          width: 100%;

          .tables-row {
            flex-direction: column;
            width: 100%;

            .table {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
