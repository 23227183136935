* {
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Open Sans", sans-serif !important;
}

#nav {
  background-color: var(--black);

  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    padding: 0 2rem;
    transition: 1.5s;
    background-color: var(--black);
    color: white;
    position: relative;

    .bookmarks {
      width: 100%;
    }

    h3 {
      transition: unset;
    }

    .links {
      display: flex;
      margin-top: 2rem;
      justify-content: center;

      .logo {
        position: absolute;
        left: 0;
      }
    }
  }

  .hide {
    transition: 0.25s;
    background-color: transparent;
  }
  .hide {
    h3 {
      opacity: 0;
      transition: 0.75s;
    }
    .nav-open-btn {
      opacity: 0;
      transition: 0.25s;
    }
  }

  a {
    position: relative;
    margin: 0 2rem;
    text-decoration: none;
    color: var(--textColor);
  }

  .phone {
    a {
      display: flex;

      p {
        margin-left: 10px;
      }
    }
  }

  a::before {
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    border-radius: 4px;
    background-color: orange;
  }

  a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }

  .nav-btn {
    padding: 0.3rem;
    visibility: hidden;
    opacity: 0;
    border: none;
    cursor: pointer;
    background: transparent;
    color: var(--textColor);
  }

  .nav-btn svg {
    font-size: 1.8rem;
  }
}

@media (max-width: 959px) {
  #nav {
    .bookmarks {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      gap: 1.5rem;
      transition: 1s;
      transform: translateX(100%);
      background-color: rgba(0, 0, 0, 0.8);

      .links {
        flex-direction: column;
        gap: 3rem;
        text-align: center;
      }
    }

    .nav-btn {
      visibility: visible;
      opacity: 1;
    }

    .nav-close-btn {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }

    .responsive-nav {
      transform: none;
      z-index: 999;
    }
  }
}
